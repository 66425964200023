import React, { useEffect, useState } from 'react'
import styles from './WonderfulAparts.module.scss'
import WonderfulHeader from '../../Components/WonderfulHeader/WonderfulHeader';
import LoadingPage from '../../Components/LoadingPage/LoadingPage';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, storage } from '../../fireebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import Gallery from '../../Components/Gallery/Gallery';

interface IApartRef {
    img: string,
    tower: string,
    price: string,
    area: string,
    floor: string,
    Name: string,
    descripton: string,
    location: string,
    color: string
}
interface IApart {
    mainImg: string,
    littleImgs: string[],
    tower: string,
    price: string,
    area: string,
    floor: string,
    Name: string,
    descripton: string,
    location: string,
    color: string,
}

const skeletonAparts =
    [{
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: "",
        location: "",
        color: "",
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: "",
        location: "",
        color: "",
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: "",
        location: "",
        color: "",
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: "",
        location: "",
        color: "",
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: "",
        location: "",
        color: "",
    } as IApart, {
        mainImg: "",
        littleImgs: ["", "", ""],
        tower: "",
        price: "",
        area: "",
        floor: "",
        Name: "",
        location: "",
        color: "",
    } as IApart
    ] as IApart[]

interface IWonderfulApartsProps {
    changePageColor: (newColor: string) => void
}

const WonderfulAparts: React.FC<IWonderfulApartsProps> = ({ changePageColor }) => {
    const [currentScroll, setCurrentScroll] = useState(0);
    const [device, setDevice] = useState(window.innerWidth > 850 ? "PC" : window.innerWidth > 780 ? "Tablet" : "Mobile")
    const [isPending, setIsPending] = useState(true);
    const [aparts, setAparts] = useState<IApart[]>(skeletonAparts);
    const [apartsRef, setApartsRef] = useState<IApartRef[]>();
    const [galleryImages, setGalleryImages] = useState<string[]>();
    const [isOpenGallery, setIsOpenGallery] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [hoverSlide, setHoverSlide] = useState(0);
    const [currentPosX, setCurrentPosX] = useState(0);
    const [swipeStartAparts, setSwipeStartAparts] = useState<number | undefined>(0)
    const [plusSwipeScroll, setPlusSwipeScroll] = useState(0);
    const [swipeSide, setSwipeSide] = useState(false);
    useEffect(() => {
        changePageColor(aparts[currentScroll].color);
    }, [currentScroll])

    const removeSkeleton = (element: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const parentElement = (element.target as HTMLElement).parentElement;

        if (parentElement) {
            // Находим дочерний элемент по классу
            const childToRemove = parentElement.querySelector('span[aria-live="polite"]');
            if (childToRemove) {
                // Удаляем дочерний элемент
                parentElement.removeChild(childToRemove);
            }
        }
    }
    const handleGallery = (i?: number) => {
        if (!isOpenGallery && i !== undefined) {
            setCurrentImage(i);
        }
        if (!isOpenGallery) {
            document.body.style.overflow = "hidden"
        }
        else {
            document.body.style.overflow = "auto"
        }
        setTimeout(() => {
            setIsOpenGallery(!isOpenGallery);
        }, 0)
    }
    const fetchGallery = async (name: string) => {
        try {
            // Ссылка на папку с изображениями
            const folderRef = ref(storage, "Gallery");

            // Получаем список всех файлов в папке
            const result = await listAll(folderRef);

            // Получаем URL для каждого изображения
            const urlPromises = result.items.map(item => getDownloadURL(item));
            const urls = await Promise.all(urlPromises);

            if (urls) {
                setGalleryImages(urls)
            }
        } catch (error) {
            console.error('Ошибка при получении ссылок на фотографии:', error);
            return [];
        }
    }

    useEffect(() => {
        const tempAparts: IApart[] = [];
        const fetchAparts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "aparts"));
                const apartsData: IApartRef[] = querySnapshot.docs.map((doc) => ({
                    ...doc.data()
                })) as IApartRef[];
                const tower = apartsData.findIndex((apart) => apart.tower === "Китай-город");

                // Если объект найден и его позиция отличается от желаемой
                console.log(tower);
                if (tower !== -1) {
                    const [targetObject] = apartsData.splice(tower, 1);
                    console.log(targetObject);
                    apartsData.splice(2, 0, targetObject);
                }
                setApartsRef(apartsData);
                for (const myApartRef of apartsData) {
                    const shortImgRef = ref(storage, myApartRef.img + "/Short");

                    // Получаем список всех файлов в папке
                    const shortImgs = await listAll(shortImgRef);

                    // Получаем URL для каждого изображения
                    const urlPromises = shortImgs.items.map(item => getDownloadURL(item));
                    const urls = await Promise.all(urlPromises);

                    if (urls) {
                        const newApart = {
                            tower: myApartRef.tower,
                            price: myApartRef.price,
                            area: myApartRef.area,
                            floor: myApartRef.floor,
                            Name: myApartRef.Name,
                            descripton: myApartRef.descripton,
                            mainImg: urls[0],
                            littleImgs: [urls[1], urls[2], urls[3]],
                            location: myApartRef.location,
                            color: myApartRef.color
                        } as IApart
                        tempAparts.push(newApart);
                    }
                }
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            } finally {
                // setCurrentScroll(Math.floor(tempAparts.length))
                setIsPending(false)
                setAparts(tempAparts);
            }
        };

        fetchAparts();

        const updateDevice = () => {
            if (window.innerWidth > 850) {
                setDevice("PC")
            }
            else if (window.innerWidth > 780) {
                setDevice("Tablet")
            }
            else {
                setDevice("Mobile")
            }
            // setDevice("Mobile")
        }
        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () => {
            window.removeEventListener('resize', updateDevice);
        };
    }, [])
    useEffect(() => {
        console.log(aparts);
    }, [aparts])
    const plusScroll = () => {
        if (aparts)
            if (currentScroll < aparts.length - 1) {
                setCurrentScroll(currentScroll + 1);
            }
    }
    const minusScroll = () => {
        if (currentScroll > 0) {
            setCurrentScroll(currentScroll - 1);
        }
    }
    const setScroll = (newScroll: number) => {
        setCurrentScroll(newScroll);
    }
    useEffect(() => {
        console.log(hoverSlide);
    }, [hoverSlide])
    useEffect(() => {
        console.log(plusSwipeScroll);
        if (Math.abs(plusSwipeScroll) > 20) {
            console.log(plusSwipeScroll);
            document.body.style.overflowY = "hidden"
        }
        else {
            document.body.style.overflowY = "auto"
        }
    }, [plusSwipeScroll])
    return (
        <div className={styles.wonderfulAparts}
            onTouchEnd={(e) => {
                if (swipeStartAparts) {
                    const clientX = e.changedTouches[0].clientX;

                    // Логируем координаты до сброса swipeStartAparts

                    if (Math.abs(clientX - swipeStartAparts) > 50) {
                        clientX < swipeStartAparts ? plusScroll() : minusScroll();
                    }

                    // После выполнения логики сбрасываем swipeStartAparts
                    setSwipeStartAparts(undefined);
                    if (document.querySelector(".aparts-slider")) {
                        (document.querySelector(".aparts-slider") as HTMLDivElement).style.transition = "transform 0.4s ease";
                    }
                    setPlusSwipeScroll(0);
                }
            }}
            onTouchMove={(e) => {
                if (swipeStartAparts) {
                    if (!isOpenGallery && !swipeSide) {
                        setSwipeSide(true);
                    }
                    setPlusSwipeScroll(e.touches[0].clientX - swipeStartAparts);
                }
            }}>
            <div className={styles.apartsWrapper} style={{
                // minHeight: `${window.innerHeight - 100}px`
            }}>
                {/* <div className={styles.TitleText}>
                    <span className={styles.text}>Наша колекция апартаментов</span>
                </div> */}
                <div className={`${styles.sliderImages} aparts-slider`}
                    onTouchStart={(e) => {
                        if (document.querySelector(".aparts-slider")) {
                            (document.querySelector(".aparts-slider") as HTMLDivElement).style.transition = "none";
                        }
                        setSwipeStartAparts(e.touches[0].clientX)
                        setPlusSwipeScroll(0);
                    }}
                    style={{
                        width: `${100 * aparts.length}%`,
                        transform: `translateX(calc(${50 - currentScroll * 100 / aparts.length}% - ${(device === "Mobile" ? (175 - hoverSlide - plusSwipeScroll) : (335.5 - hoverSlide - plusSwipeScroll))}px))`
                    }}>
                    {
                        aparts.map((apart, i) =>
                            <div className={styles.allApart} style={{
                                width: `calc(100% / ${aparts.length})`,
                            }}>
                                <div key={apart.Name + i} className={`${styles.apart} ${i} ${currentScroll} ${i === currentScroll ? styles.current : styles.hidden}`} style={{
                                    zIndex: 20 - Math.abs(currentScroll - i),
                                    transformOrigin: device === "Mobile" ? "center" : "left",
                                    pointerEvents: i !== currentScroll ? "none" : "all",
                                    visibility: Math.abs(currentScroll - i) < 3 ? "visible" : "hidden",
                                }}>

                                    {
                                        !isPending ?
                                            <span key={apart.Name + i} className={styles.Name}>{apart.Name}</span>
                                            :
                                            <span key={apart.Name + i} className={styles.Name}>
                                                <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={device !== "Mobile" ? '53px' : '26px'} width={'250px'} />
                                            </span>
                                    }
                                    <div className={styles.littleImages}>
                                        {
                                            apart.littleImgs.map((littleImage, k) =>
                                                <div style={{
                                                }} className={styles.littleImageWrapper}>
                                                    <img onClick={() => handleGallery(k + 1)} onLoad={(e) => removeSkeleton(e)} src={littleImage}></img>
                                                    <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'100%'} width={'100%'} />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className={styles.bigImageWrapper} style={{
                                        display: (device === "PC" && Math.abs(currentScroll - i) < 3 || device !== "PC" && Math.abs(currentScroll - i) < 2) ? "block" : "none",
                                    }} >
                                        <img onClick={() => handleGallery(0)} onLoad={(e) => removeSkeleton(e)} style={{
                                        }} src={apart.mainImg}></img>
                                        <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'100%'} width={'100%'} />
                                    </div>

                                </div>
                                <div key={apart.Name + i + "a"} className={`${i} ${currentScroll} ${Math.abs(currentScroll - i)} ${styles.apartInfo}  ${i === currentScroll ? styles.showInfo : styles.hideInfo}`} style={{
                                    visibility: Math.abs(currentScroll - i) < 2 ? "visible" : "hidden",
                                }} onClick={() => setScroll(i)}>
                                    <a href={`/apartments/${apart.Name}`} className={`${styles.infoBlock}`}>
                                        <div className={styles.infoBlockInner}>
                                            {!isPending ?
                                                <>
                                                    <span className={styles.info}>{apart.location}: {apart.tower}</span>
                                                    <div className={styles.line}></div>
                                                    <span className={styles.info}>Этаж: {apart.floor}</span>
                                                    <div className={styles.line}></div>
                                                    <span className={styles.info}>Площадь: {apart.area}</span>
                                                    <div className={styles.line}></div>
                                                    <span className={styles.info}>Цена: от<b> {apart.price} </b></span>
                                                    <span className={styles.more}>{device === "Mobile" ? "Забронировать" : "Забронировать"}</span>
                                                </> :
                                                <>
                                                    <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                    <div className={styles.line}></div>
                                                    <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                    <div className={styles.line}></div>
                                                    <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                    <div className={styles.line}></div>
                                                    <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                    <span className={styles.more}>
                                                        <Skeleton baseColor='#173e56' borderRadius={0} inline={false} highlightColor='#265b7c' height={'24px'} width={'100px'} />
                                                    </span>
                                                </>
                                            }
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{
                    opacity: currentScroll < 1 ? 0 : 1,
                    pointerEvents: currentScroll < 1 ? "none" : "all",
                }} onClick={() => device !== "Mobile" && device !== "Tablet" && minusScroll()} className={`${styles.slideButton} ${styles.left}`}
                    onMouseEnter={() => device !== "Mobile" && device !== "Tablet" && setHoverSlide(20)}
                    onMouseLeave={() => device !== "Mobile" && device !== "Tablet" && setHoverSlide(0)}
                    onTouchStart={() => (device === "Mobile" || device === "Tablet") && setHoverSlide(20)}
                    onTouchEnd={() => { if (device === "Mobile" || device === "Tablet") { setHoverSlide(0); minusScroll() } }}
                ></div>
                <div style={{
                    opacity: currentScroll >= aparts.length - 1 ? 0 : 1,
                    pointerEvents: currentScroll >= aparts.length - 1 ? "none" : "all",
                }} onClick={() => device !== "Mobile" && device !== "Tablet" && plusScroll()} className={`${styles.slideButton} ${styles.right}`}
                    onMouseEnter={() => device !== "Mobile" && device !== "Tablet" && setHoverSlide(-20)}
                    onMouseLeave={() => device !== "Mobile" && device !== "Tablet" && setHoverSlide(0)}
                    onTouchStart={() => (device === "Mobile" || device === "Tablet") && setHoverSlide(-20)}
                    onTouchEnd={() => { if (device === "Mobile" || device === "Tablet") { setHoverSlide(0); plusScroll() } }}
                ></div>
            </div>
            {apartsRef && isOpenGallery &&
                <Gallery device={device} handleGallery={handleGallery} imgRef={apartsRef[currentScroll].img} currentImages={currentImage} />}
        </div>
    )
}

export default WonderfulAparts