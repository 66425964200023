import React, { useEffect, useRef, useState } from 'react'
import styles from './Gallery.module.scss'
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../fireebase';
import { doc } from 'firebase/firestore';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

interface IGalleryProps {
    imgRef: string;
    currentImages: number;
    handleGallery: () => void;
    device: string;
    isApartPage?: boolean;
}

var swipeStartGallery: number | undefined = 0;
const Gallery: React.FC<IGalleryProps> = ({ imgRef, currentImages, handleGallery, device, isApartPage }) => {
    const [images, setImages] = useState<string[]>(["", "", "", "", "", "", "", "",])
    const [currentScroll, setCurrentScroll] = useState(currentImages);
    const [height, setHeight] = useState(window.innerHeight);
    const [plusSwipeScroll, setPlusSwipeScroll] = useState(0);
    useEffect(() => {
        const fetchImages = async () => {

            const folderRef = ref(storage, imgRef + "/All");

            // Получаем список всех файлов в папке
            const result = await listAll(folderRef);

            // Получаем URL для каждого изображения
            const urlPromises = result.items.map(async (item) => await getDownloadURL(item));
            const urls = await Promise.all(urlPromises);
            if (urls) {
                setImages(urls)
            }
        }
        const updateHeight = () => {
            setHeight(window.innerHeight)
        }
        fetchImages();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [imgRef])

    useEffect(() => {

    }, [images])
    const removeSkeleton = (element: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const parentElement = (element.target as HTMLElement).parentElement;
        if (parentElement) {
            // Находим дочерний элемент по классу
            const childToRemove = parentElement.querySelector('span[aria-live="polite"]');
            const childImg = parentElement.querySelector('img') as HTMLImageElement;
            if (childToRemove && childImg) {
                childImg.style.display = "block";
                // Удаляем дочерний элемент
                parentElement.removeChild(childToRemove);
            }
        }
    }

    useEffect(() => {
        slide();
    }, [currentScroll])

    const slide = () => {
        const targetChildElement = document.querySelector(`.element_${currentScroll}`) as HTMLDivElement;
        var slideElement;
        if (targetChildElement) {
            slideElement = targetChildElement.closest('.my-slider') as HTMLDivElement;
            slideElement.scrollTo({
                top: currentScroll * 65,
                behavior: 'smooth'  // Добавим плавную прокрутку
            });
        }
    }
    const plusScroll = () => {
        if (currentScroll < images.length - 1) {
            setCurrentScroll(currentScroll + 1);
        }
    }
    const minusScroll = () => {
        if (currentScroll > 0) {
            setCurrentScroll(currentScroll - 1);
        }
    }
    const handleParentClick = () => {
        handleGallery();
    };

    const handleChildClick = (event: React.MouseEvent, enable: boolean) => {
        if (enable === true)
            event.stopPropagation(); // Останавливаем всплытие клика на родителя
    };
    return (
        <div onClick={handleParentClick} className={styles.Gallery} style={{
            height: height,
            transform: isApartPage ? "translateY(0)" : "translateY(q)"
        }}
            onTouchEnd={(e) => {
                if (swipeStartGallery) {
                    const clientX = e.changedTouches[0].clientX;

                    // Логируем координаты до сброса swipeStartGallery

                    if (Math.abs(clientX - swipeStartGallery) > 100) {
                        clientX < swipeStartGallery ? plusScroll() : minusScroll();
                    }

                    // После выполнения логики сбрасываем swipeStartGallery
                    swipeStartGallery = undefined;
                    if (document.querySelector(".gallery-slider")) {
                        (document.querySelector(".gallery-slider") as HTMLDivElement).style.transition = "transform 0.4s ease";
                    }
                    setPlusSwipeScroll(0);
                }
            }}
            onTouchMove={(e) => {
                if (swipeStartGallery)
                    setPlusSwipeScroll(e.touches[0].clientX - swipeStartGallery);
            }}>
            <div onClick={(e) => handleChildClick(e, device !== "Mobile" ? true : false)} className={styles.ImgsWrapper} style={{
                height: device === "Mobile" ? `${height - 100}px` : "90%"
            }}>
                <div onClick={(e) => handleChildClick(e, device !== "Mobile" ? true : false)} className={`gallery-slider ${styles.imagesSlider}`} style={{
                    width: `${100 * images.length}%`,
                    transform: `translateX(calc(${-currentScroll * 100 / images.length}% + ${plusSwipeScroll}px))`
                }}
                    onTouchStart={(e) => {
                        if (document.querySelector(".gallery-slider")) {
                            (document.querySelector(".gallery-slider") as HTMLDivElement).style.transition = "none";
                        }
                        swipeStartGallery = e.touches[0].clientX;
                        setPlusSwipeScroll(0);
                    }}>
                    {
                        images.map((image, i) =>
                            <div className={styles.imageBlock} style={{
                                width: `${100 / images.length}%`,
                            }}>
                                <div key={image + i} className={styles.imageWrapper} onClick={(e) => handleChildClick(e, device !== "Mobile" ? true : false)}>
                                    <img onClick={(e) => handleChildClick(e, true)} onLoad={(e) => removeSkeleton(e)} src={image}></img>
                                    <Skeleton baseColor='rgb(73, 73, 73)' borderRadius={0} inline={false} highlightColor='rgb(120, 120, 120)' height={'100%'} width={'100%'} />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{
                    opacity: currentScroll < 1 ? 0 : 1,
                    pointerEvents: currentScroll < 1 ? "none" : "all",
                }} onClick={(e) => { minusScroll(); handleChildClick(e, true) }} className={`${styles.slideButtonGallery} ${styles.leftGallery}`}></div>
                <div style={{
                    opacity: currentScroll >= images.length - 1 ? 0 : 1,
                    pointerEvents: currentScroll >= images.length - 1 ? "none" : "all",
                }} onClick={(e) => { plusScroll(); handleChildClick(e, true) }} className={`${styles.slideButtonGallery} ${styles.rightGallery}`}></div>
            </div>
            <div onClick={(e) => handleChildClick(e, true)} className={`my-slider ${styles.littleImages}`}>
                {
                    images.map((image, i) =>
                        <div className={`${styles.littleImage}`} onClick={() => {
                            setCurrentScroll(i);
                        }}>
                            <div className={`element_${i} ${i === currentScroll ? styles.currentLittle : ""} ${styles.littleImageWrapper}`}>
                                <img onLoad={(e) => removeSkeleton(e)} src={image}></img>
                                <Skeleton baseColor='rgb(73, 73, 73)' borderRadius={0} inline={false} highlightColor='rgb(120, 120, 120)' height={'100% !important'} width={'100% !important'} />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Gallery