import React, { forwardRef, useEffect, useState } from 'react'
import styles from './BookMenu.module.scss'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './calendar.css'
import { IApart } from '../../types/Models/types';
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import Loading from '../../Components/Loading/Loading'
import Skeleton from 'react-loading-skeleton';
interface IBookMenuProps {
    apart: IApart,
}
const props = { placeholder: 'Please Select...' };
const BookMenu: React.FC<IBookMenuProps> = ({ apart }) => {
    const [dateRange, setDateRange] = useState([undefined, undefined]);
    const [name, setName] = useState("");
    const [number, setNumber] = useState("+7")
    const [guests, setGuests] = useState("2");
    const [startDate, endDate] = dateRange;
    const [errorDate, setErrorDate] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorNumber, setErrorNumber] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loadError, setLoadError] = useState(false);
    const send_telegram = async (number: string, name: string, dateIn: Date, dateOut: Date) => {
        var error = false;
        if ((!number || number === '') || !isPossiblePhoneNumber(number)) {
            setErrorNumber(true)
            error = true;
        }
        if (!name || name === '') {
            setErrorName(true)
            error = true;
        }
        if (!dateIn || !dateOut || dateIn == undefined || dateOut == undefined || dateIn == null || dateOut == null) {
            setErrorDate(true)
            error = true;
        }
        console.log(errorDate, dateIn, dateOut)
        if (error) {
            return;
        }
        const dateInString = `${dateIn.getFullYear()}-${String(dateIn.getMonth() + 1).padStart(2, '0')}-${String(dateIn.getDate()).padStart(2, '0')}`;
        const dateOutString = `${dateOut.getFullYear()}-${String(dateOut.getMonth() + 1).padStart(2, '0')}-${String(dateOut.getDate()).padStart(2, '0')}`;
        try {
            setLoading(true);
            setLoadError(false);
            const response = await axios.post("https://us-central1-in-premium.cloudfunctions.net/helloWorld", {
                name: name,
                phone: number,
                dateIn: dateInString,
                dateOut: dateOutString,
                apart: apart.Name,
                guests: guests
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log("Ответ от сервера:", response.data);
            setSuccess(true);
            setName("");
            setNumber("+7");
            setDateRange([undefined, undefined]);
            setGuests("2");
            setLoading(false);
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoadError(true);
                setLoading(false);
            }, 200)
        } finally {
        }
    }

    useEffect(() => {
        if (Number(guests) < 1) {
            setGuests("0")
        }
        else if (Number(guests) > 20) {
            setGuests("20")
        }
    }, [guests])
    useEffect(() => {
        setErrorNumber(false);
    }, [number])
    useEffect(() => {
        setErrorName(false);
    }, [name])
    const ExampleCustomInput = forwardRef(
        //@ts-ignore
        ({ value, onClick, className }, ref) => (
            //@ts-ignore
            <button className={className} onClick={onClick} ref={ref}>
                {value
                    ? value : "Выберите даты"}
            </button>
        ),
    );
    return (
        <div className={styles.content}>
            <div className={`${styles.success} ${success ? styles.visible : ""}`}>
                <span className={styles.bold}>
                    Спасибо за вашу заявку на бронирование!
                </span>
                <span>
                    Мы получили ваше обращение и в ближайшее время свяжемся с вами
                </span>
                <button onClick={() => setSuccess(false)} className={styles.closeSuccess}>
                    Закрыть
                </button>
            </div>
            <div className={styles.order}>
                <div className={styles.allTitle}>
                    <span className={styles.title}>
                        Оставьте заявку на бронь
                    </span>
                </div>
                <div className={styles.form}>
                    <div className={`${styles.date} ${styles.InputField} ${errorDate ? styles.error : ""}`}>
                        <span className={styles.bookTitle}>
                            Заселение и выезд
                        </span>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                //@ts-ignore
                                setDateRange(update);
                            }}
                            onCalendarOpen={() => setErrorDate(false)}
                            placeholderText='Выберите даты'
                            isClearable={true}
                            dateFormat="MMMM d, yyyy"
                            minDate={new Date()}
                            //@ts-ignore
                            customInput={<ExampleCustomInput className={styles.calendarButton} />}
                        />
                    </div>
                    <div className={`${styles.guest} ${styles.InputField}`}>
                        <span className={styles.bookTitle}>
                            Количество гостей
                        </span>
                        <div className={`${styles.input} ${styles.popupInput}`}>
                            <button onClick={() => {
                                setGuests((Number(guests) - 1).toString())
                            }} className={styles.minus}>-</button>
                            <input type='number' value={guests} onChange={(e) => setGuests(e.target.value)}></input>
                            <button onClick={() => {
                                setGuests((Number(guests) + 1).toString())
                            }} className={styles.plus}>+</button>
                        </div>
                    </div>
                    <div className={`${styles.Name} ${styles.InputField} ${errorName ? styles.error : ""}`}>
                        <span className={styles.bookTitle}>
                            Имя
                        </span>
                        <input onFocus={() => setErrorName(false)} type="text" id="first-name" name="first-name" autoComplete="given-name" value={name} onChange={(e) => setName(e.target.value)} className={`${styles.input} ${styles.textInput}`} placeholder='Введите имя'></input>
                    </div>
                    <div className={`${styles.Name} ${styles.InputField} ${errorNumber ? styles.error : ""}`}>
                        <span className={styles.bookTitle}>
                            Номер телефона
                        </span>
                        <PhoneInput
                            placeholder="Введите номер телефона"
                            value={number}
                            onFocus={() => setErrorNumber(false)}
                            limitMaxLength
                            //@ts-ignore
                            onChange={setNumber} />
                    </div>
                </div>
                <div onClick={() => {
                    // @ts-ignore
                    send_telegram(number, name, dateRange[0], dateRange[1], apart.Name)
                }} className={`${styles.bookButton} ${loading ? styles.loading : ''} ${loadError ? styles.loadError : ''}`}
                    style={{
                        pointerEvents: loading ? "none" : "all",
                        // backgroundColor: apart.color
                    }}>
                    <div className={styles.loadingWrapper}>
                        <Loading />
                    </div>
                    <span className={styles.text} style={{
                        display: 'flex',
                        justifyContent: "center",
                        gap: 5
                    }}>
                        Забронировать от

                        {apart.price ?
                            <span style={{
                                fontWeight: "bold",
                            }}>{apart.price}₽</span>
                            :
                            <Skeleton baseColor='#005656' borderRadius={0} inline={true} highlightColor='#009e9e' height={'20px'} width={'67px'} />
                        }
                    </span>
                    <span className={styles.error}>
                        Ошибка. Проверьте соединение
                    </span>
                </div>
            </div>
        </div>
    )
}

export default BookMenu